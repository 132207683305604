import Http from "../Http";

export function paymentCreate(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post('api/payments/update-plan', values)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}