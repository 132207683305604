import React, { useEffect } from 'react';
import { notification } from 'antd';
import * as PaymentService from '../../services/paymentCreate';
import { useDispatch } from "react-redux";
import { CheckCircleOutlined } from "@ant-design/icons";
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";

const StartUpPlan = () => {
    let navigate = useNavigate();
    const user_id = localStorage.getItem("user_id");
    var dispatch = useDispatch();
    const renderRazorpay = async (planId, paymentButtonId) => {
        if (window.Razorpay) {
            //console.log("Razorpay is available");

            const options = {
                key: "rzp_test_eLP2K99aMYoigU",
                currency: "INR",
                handler: function (response) {
                    onPaymentSuccess(response, planId);
                },
                amount: getPlanAmount(planId),
                element: `#${paymentButtonId}`,
            };

            //console.log("Options:", options);

            const rzp = new window.Razorpay(options);
            rzp.open();
        } else {
            console.error("Razorpay is not available");
        }
    };

    const getPlanAmount = (planId) => {
        switch (planId) {
            case 'basic':
                return 2999 * 100;
            default:
                return 0;
        }
    };

    const onPaymentSuccess = (data, planId) => {
        let planName = "";
        switch (planId) {
            case 'basic':
                planName = "Basic";
                break;
            default:
                planName = "Unknown";
                break;
        }

        const namePlan = {
            plan_name: `Startup Registration ${planName}`,
            user_id: user_id,
            payment_link_id: data.razorpay_payment_id
        }
        dispatch(PaymentService.paymentCreate(namePlan))
            .then((res) => {
                notification.open({
                    message: "Payment successfully done!",
                    icon: (
                        <CheckCircleOutlined
                            style={{
                                color: "#d62b47",
                            }}
                        />
                    ),
                });
            })
            .catch((err) => {
                notification.error({
                    message: "Something went wrong!",
                });
                console.info("Get Error Edit State...");
            });
        console.log(`Payment for ${planName} plan successful! Here's the response:`, data);
    };

    useEffect(() => {
        const initializeRazorpay = async () => {
            const script = document.createElement('script');
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.async = true;
            script.onload = () => {
                console.log("Razorpay script loaded successfully");
            };
            document.body.appendChild(script);
        };

        initializeRazorpay();

    }, []);

    const handleButtonClick = (event) => {
        const isLogin = localStorage.getItem("isLoggedIn");
        console.info({isLogin})
        if(isLogin==="true"){
            const planId = event.target.getAttribute('data-plan-id');
            const paymentButtonId = event.target.getAttribute('data-payment_button_id');
            renderRazorpay(planId, paymentButtonId);
        }else{
            Swal.fire({
                icon: "info",
                title: "Please login / signup to make payment",
                confirmButtonText: "Login",
                confirmButtonColor: "red",
              }).then((result) => {
                if(result?.isConfirmed ===true){
                    navigate("/signup")
                }
              });
        }
        };

    return (
        <section className='plans'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-md-12'>
                        <h2 className="mainTitle">Plans</h2>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className="boxPricing card basicPlan">
                            <div className="title">Basic</div>
                            <div className="price">
                                <span className="amount">₹ 2999</span>
                                <span className="time" data-lang="duration"></span>
                            </div>
                            {/* <ul>
                                <li>TAN & MSME</li>
                                <li>GST Registration</li>
                                <li>Bank Current Account</li>
                            </ul> */}
                            <a
                                className="razorpay-button"
                                data-plan-id="basic"
                                data-payment_button_id="pl_NysgPDmKOgmiHQ"
                                onClick={handleButtonClick}
                            >
                                Buy Now
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StartUpPlan;