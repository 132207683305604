import React from 'react';
import { 
    FeaturesImg,
    FeaturesIconOne,
    FeaturesIconTwo,
    FeaturesIconThree
} from '../../constant/images';

const Features = () => {
    return (
        <section className='features'>
            <div className='featuresLeft'>
                <div className='sectionTitle'>
                    <h2><span>Core Fe</span>atures</h2>
                </div>
                <h2 className='subTitle'>
                    Join Our Upcoming Events
                </h2>
                <ul>
                    <li className="featuresItem">
                        <div className="itemIcon blue">
                            <img src={FeaturesIconOne} alt="feature icon" />
                        </div>

                        <div className="wrapper">
                            <h3 className="itemTitle">Financial Wellness
                            </h3>
                            <p className="itemText">Rupezo: Nurturing financial wellness with personalized solutions
                                for stability, prosperity, and informed decision-making.
                            </p>
                        </div>
                    </li>

                    <li className="featuresItem">
                        <div className="itemIcon pink">
                            <img src={FeaturesIconTwo} alt="feature icon" />
                        </div>

                        <div className="wrapper">
                            <h3 className="itemTitle">
                                Innovative Solutions</h3>
                            <p className="itemText">Rupezo: Inspiring progress through innovative solutions that cater
                                to diverse needs, driving financial growth and transformation.
                            </p>
                        </div>
                    </li>

                    <li className="featuresItem">
                        <div className="itemIcon purple">
                            <img src={FeaturesIconThree} alt="feature icon" />
                        </div>

                        <div className="wrapper">
                            <h3 className="itemTitle">
                                Trusted Relationships
                            </h3>
                            <p className="itemText">Rupezo: Nurturing enduring trust by being a reliable partner in
                                clients' financial journeys, providing steadfast&nbsp;support</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='featuresRight'>
                <img src={FeaturesImg} className='img-fluid' alt='features image' />
            </div>
        </section>
    )
}

export default Features;