import React, { useState, useEffect } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, theme } from 'antd';
import * as FaqPageService from '../../services/faqService';
import { useDispatch } from "react-redux";
import { Spin } from 'antd';
import CTA from '../../components/CTA';

const { Panel } = Collapse;

const FAQ = () => {
    const { token } = theme.useToken();
    var dispatch = useDispatch();
    const [isContent, setIsContent] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchAboutContent();
    }, []);

    const fetchAboutContent = () => {
        dispatch(FaqPageService.faqPage())
            .then((res) => {
                setIsContent(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
                setIsLoading(false);
            });
    }

    const panelStyle = {
        marginBottom: 24,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        borderBottom: '2px solid #3131b8'
    };

    return (
        <>
            {isLoading ?
                <div className="overlay">
                    <Spin size='large' />
                </div>
                :
                <>
                    <div className='faqWrapper'>
                        <div className="container">
                            <div className='row justify-content-center mt-5'>
                                <div className='col-md-10'>
                                    <div className='sectionTitle'>
                                        <h2><span>FA</span>Qs</h2>
                                    </div>
                                    <h2 className='subTitle'>
                                        Frequently Asked Questions
                                    </h2>
                                    {/* <p className="sectionText">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pellentesque neque eget diam posuere porta. Quisque ut nulla at nunc vehicula lacinia. Proin adipiscing porta tellus, ut feugiat nibh adipiscing sit amet. In eu justo a felis faucibus ornare vel id metus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; In eu libero ligula.
                                    </p> */}
                                </div>
                            </div>
                            <div className='row mt-5 justify-content-center'>
                                <div className='col-md-10'>
                                    <Collapse
                                        bordered={false}
                                        defaultActiveKey={['0']}
                                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                        style={{
                                            background: token.colorBgContainer,
                                        }}
                                    >
                                        {isContent.map((item, index) => (
                                            <Panel header={item.question} key={index} style={panelStyle}>
                                                <p>{item.answer}</p>
                                            </Panel>
                                        ))}
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CTA />
                </>
            }
        </>
    )
}

export default FAQ;