import React from 'react';
import { useNavigate } from "react-router-dom";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import {
    AboutBg,
    AboutIcon,
    AboutIconOne
} from '../../constant/images'

const renderBulletPoints = (htmlContent) => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(htmlContent, 'text/html');

    const listItems = parsedHtml.querySelectorAll('li');

    return (
        <ul className="aboutUl">
            {Array.from(listItems).map((item, index) => (
                <li key={index}>
                    <IoIosCheckmarkCircleOutline />
                    <span dangerouslySetInnerHTML={{ __html: item.innerHTML }} />
                </li>
            ))}
        </ul>
    );
}

const AboutSection = (props) => {
    let navigate = useNavigate();

    const handlenavigate = () => {
        navigate('/about-us')
    }

    return (
        <section className='about'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='aboutLeft'>
                            <div className='imgBox'>
                                <img src={AboutBg} className='aboutBg' alt='about bg' />
                                <img src={props.AboutImg} className='aboutImg' alt='about person' />
                                <img src={AboutIcon} className='icon-1 smooth-zigzag-anim-1' alt='about bg' />
                                <img src={AboutIconOne} className='icon-2 smooth-zigzag-anim-3' alt='about bg' />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='aboutRight'>
                            <div className='sectionTitle'>
                                <h2><span>Abou</span>t us</h2>
                            </div>
                            <h2 className='subTitle'>
                                {props.subTitle}
                            </h2>
                            <p className="sectionText" dangerouslySetInnerHTML={{ __html: props.sectionText}}></p>
                            {renderBulletPoints(props.aboutUl)}
                            <button className="btn btn-primary" onClick={handlenavigate}>
                                <p className="btn-text">{props.buttonText}</p>
                                <span className="square"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSection;