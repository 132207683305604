import React, { useState, useEffect } from 'react';
import Features from './features';
import CTA from '../../components/CTA';
import * as AboutPageService from '../../services/aboutUsService';
import { useDispatch } from "react-redux";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import {
    AboutBg,
    AboutIcon,
    AboutIconOne,
} from '../../constant/images'
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Spin } from 'antd';

const AboutUs = () => {
    var dispatch = useDispatch();
    const [isContent, setIsContent] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchAboutContent();
    }, []);

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])

    const fetchAboutContent = () => {
        dispatch(AboutPageService.aboutUsPage())
            .then((res) => {
                setIsContent(res.data[0]);
                setIsLoading(false);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
                setIsLoading(false);
            });
    }


    return (
        <>
            {isLoading ?
                <div className="overlay">
                    <Spin size='large' />
                </div>
                :
                <div className='aboutUsWrapper #about-cont'>
                    <section className='about'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='aboutLeft'>
                                        <div className='imgBox'>
                                            <img src={AboutBg} className='aboutBg' alt='about bg' />
                                            <img src={isContent.banner_image} className='aboutImg' alt='about person' />
                                            <img src={AboutIcon} className='icon-1 smooth-zigzag-anim-1' alt='about bg' />
                                            <img src={AboutIconOne} className='icon-2 smooth-zigzag-anim-3' alt='about bg' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 d-flex justify-content-center align-items-center'>
                                    <div className='aboutRight'>
                                        <div className='sectionTitle'>
                                            <h2><span>Abou</span>t us</h2>
                                        </div>
                                        <h2 className='subTitle'>
                                            {isContent.banner_title}
                                        </h2>
                                        <p className="sectionText" dangerouslySetInnerHTML={{ __html: isContent.short_description }} />
                                        {/* <ul className="aboutUl">
                                            <li>
                                                <IoIosCheckmarkCircleOutline />
                                                <p>Your trusted financial partner.</p>
                                            </li>

                                            <li>
                                                <IoIosCheckmarkCircleOutline />
                                                <p>We offer diverse financial solutions.</p>
                                            </li>

                                            <li>
                                                <IoIosCheckmarkCircleOutline />
                                                <p>Committed to innovation, customer satisfaction, and your financial&nbsp;well-being.</p>
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Features />
                    <section className='instructor aboutInstructor'>
                        <div className='container'>
                            <div className='row justify-content-center'>
                                <div className='col-md-12'>
                                    <h2 className="sectionSubtitle">Board Of Director</h2>
                                </div>
                                {isContent.team_data.map((team, index) => {
                                    return (
                                        <div className='col-lg-3 col-md-6' key={index}>
                                            <div className="card instructorCard">
                                                <div className="instructorImgBox">
                                                    <img src={team.team_image} alt="instructor" />
                                                    <div className="socialLink">
                                                        <a href={team.team_fb} className="facebook">
                                                            <FaFacebook />
                                                        </a>

                                                        <a href={team.team_instagram} className="instagram">
                                                            <FaInstagram />
                                                        </a>

                                                        <a href={team.team_twitter} className="twitter">
                                                            <FaXTwitter />
                                                        </a>
                                                    </div>
                                                </div>
                                                <h4 className="instructorName">{team.team_title}</h4>
                                                <p className="instructorTitle">{team.team_description}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                    <CTA />
                </div>
            }
        </>
    )
}

export default AboutUs;