import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Homepage from "../pages/homepage";
import Services from "../pages/services";
import Contact from "../pages/contact";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Login from "../pages/auth"
import AboutUs from "../pages/about";
import Blogs from "../pages/blogs";
import Profile from "../pages/profile";
import ForgotPassword from "../pages/auth/forgotPassword";
import FAQ from "../pages/faq";
import BlogDetails from "../pages/blogs/blogDetails";
import Events from "../pages/events";
import EventsDetail from "../pages/events/eventDetails";
import ReturnPolicy from "../pages/others/returnPolicy";
import PrivacyPolicy from "../pages/others/privacyPolicy";
import Terms from "../pages/others/termsConditions";
import ResetPassword from "../pages/auth/resetPassword";

const AppRoute = () => {
    const location = useLocation();

    const isLoginPage = location.pathname === '/signup';
    const isForgotPage = location.pathname === '/forgot-passowrd';
    const isResetPage = location.pathname === '/reset-password';

    const isLoggedIn = localStorage.getItem("isLoggedIn");

    return (
        <>
            {!isLoginPage && !isForgotPage && !isResetPage && <Header />}
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/services/:top_heading" element={<Services />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/blog-details/:slug" element={<BlogDetails />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/signup" element={<Login />} />
                <Route path="/forgot-passowrd" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/events" element={<Events />} />
                <Route path="/event-details/:id" element={<EventsDetail />} />
                <Route path="/return-policy" element={<ReturnPolicy />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<Terms />} />
            </Routes>
            {!isLoginPage && !isForgotPage && !isResetPage && <Footer />}
        </>
    );
}

export default AppRoute;
