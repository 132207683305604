import React, { useState, useEffect } from 'react';
import { UserAvatar } from '../../constant/images';
import { FaEnvelopeOpen, FaMobileScreenButton, FaLocationDot, FaPencil } from "react-icons/fa6";
import { Space, Table, Modal, Spin, Popconfirm } from 'antd';
import { FaEye, FaTrash } from "react-icons/fa";
import DocumentForm from './doucmentForm';
import ProfileForm from './profileForm';
import { useDispatch } from "react-redux";
import * as UserService from '../../services/userService';

const Index = () => {
    var dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDocumentOpen, setIsDocumentOpen] = useState(false);
    const [documentContent, setDocumentContent] = useState("");
    const [userData, setUserData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const user_id = localStorage.getItem("user_id");
    const [docData, setDocData] = useState([]);

    useEffect(() => {
        fetchUserContent(user_id);
    }, [user_id]);

    const fetchUserContent = (user_id) => {
        dispatch(UserService.getUserInfo(user_id))
            .then((res) => {
                setUserData(res.data);
                setIsLoading(false)
                setDocData(res.data.document);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
                setIsLoading(false);
            });
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showDocumentModal = (documentContent) => {
        setDocumentContent(documentContent);
        setIsDocumentOpen(true);
    };

    const handleDocument = () => {
        setIsDocumentOpen(false);
    };

    const cancleDocumentModal = () => {
        setIsDocumentOpen(false);
    };

    const deleteDocument = (user_id, document_name) => {
        dispatch(UserService.deleteDocument(user_id, document_name))
            .then(() => {
                setDocData(docData.filter(doc => doc.user_id !== user_id || doc.name !== document_name));
                fetchUserContent(user_id);
            })
            .catch((err) => {
                console.error("Error deleting document:", err);

            });
    }

    const dataWithIndex = docData.map((item, index) => ({ ...item, index: index + 1 }));

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'Document Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <button type="button" className='btn downloadBtn' onClick={() => showDocumentModal(record.path)}>
                        <FaEye />
                    </button>
                    <Popconfirm
                        title="Are you sure to delete this document?"
                        onConfirm={() => deleteDocument(user_id, record.name)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button type="button" className='btn deleteBtn'>
                            <FaTrash />
                        </button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    };

    return (
        <>
            {
                isLoading ?
                    <div className='overlay'>
                        <Spin size='large' />
                    </div>
                    :
                    <>
                        <div className='profileWrapper'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <div className='card profileCard'>
                                            <div className='card-body'>
                                                <div className='topRow text-end'>
                                                    <button type='button' className='btn editButton' onClick={showModal}>
                                                        <FaPencil /> Edit
                                                    </button>
                                                </div>
                                                <div className='imgWrap'>
                                                    {/* <img src={UserAvatar} className='img-fluid' alt='profile' /> */}
                                                    <img src={userData && userData?.profile_image && userData?.profile_image !== "https://api-rupezo.aleaspeaks.com/storage/" && userData?.profile_image !== "https://api.rupezo.com//storage/" ? userData.profile_image : UserAvatar} className='img-fluid' alt='profile' />
                                                </div>
                                                <div className='nameWrap'>
                                                    <h3 className='userName'>{userData ? userData?.username : "N/A"}</h3>
                                                </div>
                                                <div className='detailWrap'>
                                                    <ul className='detailList'>
                                                        <li className='detailsItem'>
                                                            <span className='icon'><FaEnvelopeOpen /></span>
                                                            <span>{userData && userData?.email !== null ? userData?.email : "N/A"}</span>
                                                        </li>
                                                        <li className='detailsItem'>
                                                            <span className='icon'><FaMobileScreenButton /></span>
                                                            <span>{userData && userData?.phone !== null ? userData?.phone : "N/A"}</span>
                                                        </li>
                                                        <li className='detailsItem'>
                                                            <span className='icon'><FaLocationDot /></span>
                                                            <span>{userData && userData?.address != null ? userData?.address : "N/A"}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-9'>
                                        <div className='card detailCard'>
                                            <div className='card-header cardHeader'>
                                                <h3 className='mb-0'>Purchased Plan</h3>
                                            </div>
                                            <div className='card-body p-4'>
                                                <div className='planDetails'>
                                                    <ul className='plansDtlList'>
                                                        <li className='plansItmes'>
                                                            <p className='dtlTitle'>Purchased Plan</p>
                                                            <p className='dtlText'>{userData && userData?.payment[0]?.plan_name ? userData?.payment[0]?.plan_name : "N/A"}</p>
                                                        </li>
                                                        <li className='plansItmes'>
                                                            <p className='dtlTitle'>Amount</p>
                                                            <p className='dtlText'>₹ {userData && userData?.payment[0]?.amount ? userData?.payment[0]?.amount : "N/A"}</p>
                                                        </li>
                                                        <li className='plansItmes'>
                                                            <p className='dtlTitle'>Purchased Date</p>
                                                            <p className='dtlText'>{userData && userData?.payment[0]?.created_at ? formatDate(userData?.payment[0]?.created_at) : "N/A"}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className='card-header cardHeader radiusNone'>
                                                <h3 className='mb-0'>Uploaded Documents</h3>
                                            </div>
                                            <div className='card-body p-0 pb-5'>
                                                <div className='tableWrap'>
                                                    <div className='table-responsive'>
                                                        <Table
                                                            columns={columns}
                                                            dataSource={dataWithIndex}
                                                            pagination={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {docData?.length !== 5 && (
                                                <>

                                                    <div className='card-header cardHeader radiusNone'>
                                                        <h3 className='mb-0'>Add New Documents</h3>
                                                    </div>
                                                    <div className='card-body'>
                                                        <DocumentForm userData={userData}  />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal
                            title="Edit Profile"
                            open={isModalOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            footer={false}
                            className='formModal'
                        >
                            <ProfileForm closeModal={handleOk} userData={userData} />
                        </Modal>
                        <Modal
                            title="Document Preview"
                            open={isDocumentOpen}
                            onOk={handleDocument}
                            onCancel={cancleDocumentModal}
                            footer={false}
                            className='formModal'
                        >
                            <iframe src={documentContent} title="Document Preview" width="100%" height="400px" />
                        </Modal>
                    </>
            }
        </>
    )
}

export default Index;