import React from 'react';
import { 
    BannerLine,
    ProprietorshipImg,
    DecoShapeOne,
    DecoShapeTwo,
    DecoShapeThree,
    DecoShapeFour
} from '../../constant/images';
import { useNavigate } from "react-router-dom";

const Banner = (props) => {

    let navigate = useNavigate();

    const handlenavigate = () => {
        navigate('/contact-us')
    }

    return(
        <section className='home serviceHome'>
            <div className='container'>
                <div className="deco-shape shape-1">
                    <img src={DecoShapeOne} alt="art shape" width="78" />
                </div>
                <div className="deco-shape shape-2">
                    <img src={DecoShapeTwo} alt="art shape" width="55" />
                </div>
                <div className="deco-shape shape-3">
                    <img src={DecoShapeThree} alt="art shape" width="120" />
                </div>
                <div className="deco-shape shape-4">
                    <img src={DecoShapeFour} alt="art shape" width="30" />
                </div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='homeLeft'>
                            <h2 className="mainHeading">
                                <span className="underlineImg">{props.title} <img src={BannerLine} alt="Line" /></span>
                            </h2>
                            <p className="sectionText" dangerouslySetInnerHTML={{ __html: props.description }} />
                            <a className='contactBtn'>
                                <button className="btn btn-secondary" onClick={handlenavigate}>
                                    <p className="btn-text">{props.buttonText}</p>
                                    <span className="square"></span>
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='homeRight'>
                            <div className='imgBox'>
                                <img src={props.bannerImage} alt="banner image" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner;