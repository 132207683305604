const Logo = require('../assets/images/logo.png');
const BannerLine = require('../assets/images/bannerLine.png');
const BannerImage = require('../assets/images/bannerImg.png');
const BannerIconOne = require('../assets/images/bannerIconOne.png');
const BannerIconTwo = require('../assets/images/bannerIconTwo.png');
const BannerIconThree = require('../assets/images/bannerIconThree.png');
const BannerIconFour = require('../assets/images/bannerIconFour.png');
const CategoryIconOne = require('../assets/images/categoryIcon1.webp');
const CategoryIconTwo = require('../assets/images/categoryIcon2.webp');
const CategoryIconThree = require('../assets/images/categoryIcon3.webp');
const CategoryIconFour = require('../assets/images/categoryIcon4.webp');
const CategoryIconFive = require('../assets/images/categoryIcon5.webp');
const CategoryIconSix = require('../assets/images/categoryIcon6.webp');
const AboutBg = require('../assets/images/aboutImgBg.webp');
const AboutImg = require('../assets/images/aboutImg.webp');
const AboutIcon = require('../assets/images/aboutIcon.webp');
const AboutIconOne = require('../assets/images/aboutIconOne.webp');
const CourseImgOne = require('../assets/images/course1.webp');
const CourseImgTwo = require('../assets/images/course2.webp');
const CourseImgThree = require('../assets/images/course3.webp');
const CourseImgFour = require('../assets/images/course4.webp');
const CourseImgFive = require('../assets/images/course5.webp');
const CourseImgSix = require('../assets/images/course6.webp');
const EventImg = require('../assets/images/eventImg.webp');
const FeaturesImg = require('../assets/images/featureImg.webp');
const FeaturesIconOne = require('../assets/images/featureIcon1.webp');
const FeaturesIconTwo = require('../assets/images/featureIcon2.webp');
const FeaturesIconThree = require('../assets/images/featureIcon3.webp');
const InstructorOne = require('../assets/images/instructor1.webp');
const InstructorTwo = require('../assets/images/instructor2.webp');
const ClientImg = require('../assets/images/clientImg.webp');
const Quote = require('../assets/images/quote.webp');
const BlogOne = require('../assets/images/blog1.webp');
const BlogTwo = require('../assets/images/blog2.webp');
const BlogThree = require('../assets/images/blog3.webp');
const CtaBg = require('../assets/images/ctaBg.webp');
const ContactImg = require('../assets/images/contactImg.webp');
const ProprietorshipImg = require('../assets/images/propritorshipFirm.webp');
const DecoShapeOne = require('../assets/images/shape1.webp');
const DecoShapeTwo = require('../assets/images/shape2.webp');
const DecoShapeThree = require('../assets/images/shape3.webp');
const DecoShapeFour = require('../assets/images/shape4.webp');
const LoginImg = require('../assets/images/login_image.png');
const SignUpImg = require('../assets/images/signup_image.png');
const UserAvatar = require('../assets/images/avatar.png');
const EventImage = require('../assets/images/eventImg.png');
const WhatsAppIcon = require('../assets/images/whatsapp.png');
const ChatIcon = require('../assets/images/chatboxx.webp');
const ContactUs_Blog = require('../assets/images/contactus_blog.png')

export {
    Logo, 
    BannerLine, 
    BannerImage, 
    BannerIconOne, 
    BannerIconTwo, 
    BannerIconThree, 
    BannerIconFour, 
    CategoryIconOne, 
    CategoryIconTwo, 
    CategoryIconThree, 
    CategoryIconFour, 
    CategoryIconFive, 
    CategoryIconSix,
    AboutBg,
    AboutImg,
    AboutIcon,
    AboutIconOne,
    CourseImgOne,
    CourseImgTwo,
    CourseImgThree,
    CourseImgFour,
    CourseImgFive,
    CourseImgSix,
    EventImg,
    FeaturesImg,
    FeaturesIconOne,
    FeaturesIconTwo,
    FeaturesIconThree,
    InstructorOne,
    InstructorTwo,
    ClientImg,
    Quote,
    BlogOne,
    BlogTwo,
    BlogThree,
    CtaBg,
    ContactImg,
    ProprietorshipImg,
    DecoShapeOne,
    DecoShapeTwo,
    DecoShapeThree,
    DecoShapeFour,
    LoginImg,
    SignUpImg,
    UserAvatar,
    EventImage,
    WhatsAppIcon,
    ChatIcon,
    ContactUs_Blog
};