import React, { useState } from 'react';
import { LoginImg } from '../../constant/images';
import ResetForm from './resetForm';

const ResetPassword = () => {

    return (
        <div className="authWrapper">
            <div className="formPanel">
                <div className="loginSignup">
                    <ResetForm />
                </div>
            </div>
            <div className="panel">
                <div className="innerPanel leftPanel">
                    <div className="panelHeading">
                        <h3>Reset Password</h3>
                        <p>
                            Join us and start your journey by creating an account today
                        </p>
                    </div>
                    <div className="imgBox">
                        <img src={LoginImg} className="img-fluid image" alt="login" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;