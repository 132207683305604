import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ContactSection from './contact';
import Banner from './banner';
import ContentSection from './content';
import * as ServicePage from '../../services/servicesPage';
import { useDispatch } from "react-redux";
import { Spin } from 'antd';
import Plans from '../homepage/plans';
import PartnershipPlans from './partnershipPlans';
import LibilitiesPlans from './libilitiesPlans';
import IECPlans from './IECPlans';
import TradePlan from './TradePlan';
import FssaiPlan from './fssaiPlan';
import UdhyamPlan from './udhyamPlan';
import ISOPlan from './ISOPlan';
import NidhiFirmPlan from './nidhiFirmPlan';
import FirmRegistrationPlan from './firmRegistrationPlan';
import NgoRegistrationPlan from './ngoRegistrationPlan';
import StartUpPlan from './startUpPlan';
import GemRegistrationPlans from './gemRegistrationPlans';
import FoodPlan from './foodPlan';
import OnePersonPlans from './onePersonPlans';
import GstPlans from "./gstPlans"
import TradeMarkFilling from './tradeMarkFilling';
import HinduUndividedFamily from './hinduUndividedFamily';
import PrivateLTDFirm from './privateLTDFirm';

const Services = () => {
    var dispatch = useDispatch();
    const { top_heading } = useParams();
    const [serviceData, setServiceData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        fetchServiceData();
    }, [top_heading]);

    console.log({'top': top_heading})

    const fetchServiceData = () => {
        dispatch(ServicePage.servicePage())
            .then((res) => {
                console.info({res})
                const service = res.data.find(data => data.top_heading === top_heading);

                setServiceData(service);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error("Error fetching service data:", err);
                setIsLoading(false);
            });
    }

    return (
        <>
            {
                isLoading ?
                    <div className='overlay'>
                        <Spin size="large" />
                    </div> :
                    <div className='servicesWrapper'>
                        <Banner
                            title={serviceData?.banner_title}
                            description={serviceData?.banner_description}
                            buttonText={serviceData?.cta_button_name}
                            bannerImage={serviceData?.banner_images}
                        />
                        {
                            top_heading === 'Proprietorship Firm' ?  <Plans /> :
                            top_heading === 'Partnership Firm' ? <PartnershipPlans /> : 
                            top_heading === 'Limited Libilities Firm (LLP)' ?  <LibilitiesPlans /> : 
                            top_heading === 'Import and Export Licence' ? <IECPlans /> : 
                            top_heading === 'Trade License' ? <TradePlan /> :
                            top_heading === 'FSSAI Licence' ? <FssaiPlan /> : 
                            top_heading === 'Udhyam certificate (MSME)' ? <UdhyamPlan /> : 
                            top_heading === 'ISO Certification' ? <ISOPlan /> : 
                            top_heading === 'NIDHI Firm' ? <NidhiFirmPlan /> : 
                            top_heading === 'Firm Registration (MCA)' ? <FirmRegistrationPlan /> : 
                            top_heading === 'NGO Registration' ? <NgoRegistrationPlan /> : 
                            top_heading === 'Startup Registration' ? <StartUpPlan /> : 
                            top_heading === 'GEM Registration' ? <GemRegistrationPlans /> : 
                            top_heading === 'F&B Shop Establishment Licence' ? <FoodPlan /> : 
                            top_heading === 'One Person Pvt. Limited Firm' ? <OnePersonPlans /> :
                            top_heading === 'GST Registration' ? <GstPlans /> :
                            top_heading === 'Trade Mark filling' ? <TradeMarkFilling /> :
                            top_heading === 'Hindu Undivided Family (HUF)' ? <HinduUndividedFamily /> :
                            top_heading === "Private Ltd. Firm" ? <PrivateLTDFirm /> :""
                        }
                        <ContentSection 
                            overviewTitle={serviceData?.overview_title}
                            overviewDescription={serviceData?.overview_description}
                            benifitsTitle={serviceData?.benefits_title}
                            benifitsDescription={serviceData?.benefits_description}
                            procedureTitle={serviceData?.procedure_title}
                            procedureDescription={serviceData?.procedure_description}
                            documentsTitle={serviceData?.documentations_title}
                            documentsDescription={serviceData?.documentations_description}
                            supportTitle={serviceData?.support_title}
                            supportDescription={serviceData?.support_description}
                        />
                        
                        <ContactSection />
                    </div>
            }
        </>
    )
}

export default Services;