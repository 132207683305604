import React, { useState } from "react";
import { Button, Form, Input, notification,Spin } from 'antd';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as ResetPasswordServices from "../../services/resetPasswordService";

const ForgotForm = () => {
    const [form] = Form.useForm();
    let navigate = useNavigate();
    var dispatch = useDispatch();
    const [loader, setLoader] = useState(false);

    const [forgotInput, setForgotInput] = useState({
        email: "",
    });
    const [submitting, setSubmitting] = useState(false);

    const handleInput = (e) => {
        setForgotInput({ ...forgotInput, [e.target.name]: e.target.value });
    };

    const onFinish = async (e) => {
        setSubmitting(true);
        setLoader(true);

        try {
            await form.validateFields(
                [
                    'email',
                ]
            );

            const data = {
                email: e?.email,
            };

            
            dispatch(ResetPasswordServices.sendPasswordLink(data))
                .then((res) => {
                    setLoader(false);
                    form.resetFields();
                    notification.success({
                        message: 'Your reset password link has been sent!'
                    })
                    Swal.fire({
                        icon: "success",
                        title: "Password link sent successfully!",
                        confirmButtonColor:"#348fef",
                      }).then((result) => {
                      });
                    setSubmitting(false)
                    navigate('/signup')
                })
                .catch((err) => {
                    setLoader(false);
                    console.log(err)
                    setSubmitting(false)
                    notification.error({
                        message: 'Something went wrong!'
                    })
                });
        } catch {
            console.log('Validation error')
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            name="ForgotForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className='ForgotForm'
            form={form}
        >
            <h2>Forgot Password</h2>
            <Form.Item
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Please input your email!',
                    },
                ]}
            >
                <Input
                    name="email"
                    className='formControl'
                    onBlur={handleInput}
                    value={forgotInput.email}
                    placeholder='Email'
                    prefix={<i className="fas fa-user" aria-hidden="true"></i>}
                />
            </Form.Item>

            <Form.Item>
                <Button  disabled={submitting}type="primary" htmlType="submit" className='loginBtn'>
                {loader ? (<><Spin /> <p>Processing</p></>):(<>Submit</>)}
                </Button>
            </Form.Item>
        </Form>
    )
}

export default ForgotForm;