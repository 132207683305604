import React from "react";

const ReturnPolicy = () => {
    return (
        <div className="policyWrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className='col-md-12 mb-5'>
                            <div className='sectionTitle'>
                                <h2><span>Return </span>&nbsp;Policy</h2>
                            </div>
                            <h2 className='subTitle'>
                                Refund and Returns Policy
                            </h2>
                        </div>
                        <div className="col-md-12">
                            <div className="policyContent">
                                <h3>Return</h3>
                                <p style={{color:"black"}}>We have a 7-day return policy, which means you have 7 days after receiving your item to request a return.</p>
                                <p style={{color:"black"}}>To be eligible for a return, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging. You'll also need the receipt or proof of purchase.</p>
                                <p style={{color:"black"}}>To start a return, you can contact us at <a href="mailto:info@rupezo.com">info@rupezo.com</a>.</p>
                                <p style={{color:"black"}}>If your return is accepted, we'll send you a return shipping label, as well as instructions on how and where to send your package. Items sent back to us without first requesting a return will not be accepted.</p>
                                <p style={{color:"black"}}>You can always contact us for any return questions at <a href="mailto:info@rupezo.com">info@rupezo.com</a>.</p>
                                <h3>Refunds</h3>
                                <p style={{color:"black"}}>We will notify you once we've received and inspected your return to let you know if the refund was approved or not. If approved, you'll be automatically refunded on your original payment method within 10 business days. Please remember it can take some time for your bank or credit card company to process and post the refund too.</p>
                                <p style={{color:"black"}}>If more than 15 business days have passed since we've approved your return, please contact us at <a href="mailto:info@rupezo.com">info@rupezo.com</a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReturnPolicy;