import React, { useState } from "react";
import { Button, Form, Input, notification } from 'antd';
import * as ContactServices from "../../services/contactService";
import { useDispatch } from "react-redux";
import { CheckCircleOutlined } from "@ant-design/icons";
import {ContactUs_Blog} from "../../constant/images"

const ContactFormBlog = () => {
    var dispatch = useDispatch();
    const [form] = Form.useForm();

    const [contactInput, setContactInput] = useState({
        name: "",
        email: "",
        phone: ""
    });

    const handleInput = (e) => {
        setContactInput({ ...contactInput, [e.target.name]: e.target.value });
    };

    const onFinish = async (e) => {
        try {
            await form.validateFields(
                [
                    'name',
                    'email',
                    'phone'
                ]
            );

            const data = {
                name: contactInput.name,
                email: contactInput.email,
                phone: contactInput.phone
            };


            dispatch(ContactServices.contact(data))
                .then((res) => {
                    console.info({ res });
                    notification.open({
                        message: "Inquiry submitted successfully!",
                        icon: (
                            <CheckCircleOutlined
                                style={{
                                    color: "green",
                                }}
                            />
                        ),
                    });
                    form.resetFields();
                    setContactInput({
                        name: "",
                        email: "",
                        phone: ""
                    });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
        } catch {
            console.log('Validation error')
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const validatePhoneNumber = (rule, value, callback) => {
        if (!value) {
          callback("Please enter your phone number."); // Handle empty value case
        } else {
          const phoneRegex = /^\d{10}$/;
          if (!phoneRegex.test(value)) {
            callback(
              "Phone number must be 10 digits long and contain only numbers."
            );
          } else {
            callback(); // Pass validation
          }
        }
      };

    return (
        <Form
            name="contactForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout='vertical'
            form={form}
            className="form-container"
        >
        <div className="contact-blog-container">
            <div className='row contact-blog'>
            <div className="image-container">
            <img src={ContactUs_Blog} alt="Contact" />
            </div>
                <div className='col-md-12'>
                    <Form.Item
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your name!',
                            },
                        ]}
                    >
                        <Input
                            name="name"
                            className='form-control'
                            placeholder='Name *'
                            onBlur={handleInput}
                            value={contactInput.name}
                        />
                    </Form.Item>
                </div>
                <div className='col-md-12'>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Email is required',
                            },
                        ]}
                    >
                        <Input
                            name="email"
                            className='form-control'
                            placeholder='Email *'
                            onBlur={handleInput}
                            value={contactInput.email}
                        />
                    </Form.Item>
                </div>
                <div className='col-md-12'>
                    <Form.Item
                        name="phone"
                        rules={[
              {
                validator: validatePhoneNumber,
              },
            ]}
                    >
                        <Input
                            name="phone"
                            className='form-control'
                            placeholder='Phone *'
                            onBlur={handleInput}
                            value={contactInput.phone}
                        />
                    </Form.Item>
                </div>
                <div className='col-md-12 text-center'>
                    <Button type="primary" htmlType="submit" className='submitButton'>
                        Contact
                    </Button>
                </div>
            </div>
        </div>
        </Form>
    )
}

export default ContactFormBlog;