import React, { useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Upload, notification } from 'antd';
import { useDispatch } from "react-redux";
import * as UserService from '../../services/userService';

const DocumentForm = ({ userData }) => {

    const [form] = Form.useForm();
    var dispatch = useDispatch();
    const user_id = localStorage.getItem("user_id");

    const [fileLists, setFileLists] = useState([]);
    const [fileData, setData] = useState([]);
    const [loadings, setLoadings] = useState([]);

    const enterLoading = (index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 6000);
    };

    useEffect(() => {
        console.log("state has been updated");
        console.log({ fileData })
    }, [fileData])

    const onFinish = async (values) => {
        enterLoading(0)
        let docNames = [];
        values.documents.forEach((doc) => {
            docNames.push(doc.document_name)
        })

        try {

            const formData = new FormData();
            formData.append('username', userData?.username);
            formData.append('email', userData?.email);
            formData.append('phone', userData?.phone);
            formData.append('address', userData?.address);
            formData.append('user_id', user_id);
            // console.log({ 'fileData': fileData })
            formData.append('document', JSON.stringify(fileData));
            formData.append('documentName', JSON.stringify(docNames));
            formData.append('completeDocs', JSON.stringify(values.documents));
            const res = await dispatch(UserService.userService(formData));

            if (res.success === true) {
                form.resetFields();
                setFileLists([]);
                window.location.reload();
            } else {
                console.log('error');
            }
        } catch (err) {
            console.log(err);
            notification.error({
                message: 'Something went wrong!'
            });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleChange = (info, index) => {
        console.log({ info })
        if (info.file.status === "uploading" && !info.event) {
            const newFileLists = [...fileLists];
            newFileLists[index] = info;
            setFileLists(newFileLists);
            const reader = new FileReader();
            reader.readAsDataURL(info.file.originFileObj);

            reader.onload = (e) => convertBase64(e.target.result);
        }
    };

    const convertBase64 = (url) => {
        const base64 = url.split(',')[1];
        console.log("Converted Base64:", base64);
        let newFileData = [...fileData];
        newFileData.push(base64)
        setData(newFileData);
    };


    return (
        <Form
            name="documentForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className='documentForm'
            form={form}
        >
            <Form.List name="documents">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <Space
                                key={key}
                                align="baseline"
                            >
                                <Form.Item
                                    {...restField}
                                    name={[name, 'document_name']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Missing document name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Document Name" className='formControl' />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'document']}
                                    noStyle
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please upload a PNG, JPG, or PDF file',
                                        },
                                    ]}
                                >
                                    <Upload
                                        onChange={handleChange}
                                        fileList={fileLists[index]}
                                        listType="text"
                                        accept=".png,.jpg,.jpeg,.pdf"
                                        maxCount={1}
                                    >
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} className='text-danger' />
                            </Space>
                        ))}
                        {fields.length <= parseInt(5 - userData?.document?.length - 1) && (
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add New Document
                                </Button>
                            </Form.Item>
                        )}
                    </>
                )}
            </Form.List>
            <Form.Item className='text-center'>
                <Button 
                    type="primary" 
                    htmlType="submit" 
                    className="submitBtn"
                    loading={loadings[0]}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default DocumentForm;

