import React, { useState, useEffect } from 'react';
import { FaRegCalendarAlt  } from "react-icons/fa";
import { IoIosTime } from "react-icons/io";
import * as BlogService from '../../services/blogService';
import { useDispatch } from "react-redux";
import { Spin } from 'antd';
import { useLocation } from "react-router-dom";

const Blogs = () => {
    var dispatch = useDispatch();
    const location = useLocation();
    const [isContent, setIsContent] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const isBlogPage = location.pathname === '/blogs';

    useEffect(() => {
        fetchBlogContent();
    }, []);

    const fetchBlogContent = () => {
        dispatch(BlogService.blog())
            .then((res) => {
                setIsContent(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
                setIsLoading(false);
            });
    }

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    };
    const formatTimeIST = (time) => {
        const dateTimeString = time;
                const dateTimeUTC = new Date(dateTimeString);
                const hours = dateTimeUTC.getUTCHours();
                const minutes = dateTimeUTC.getUTCMinutes();
                const seconds = dateTimeUTC.getUTCSeconds();
                
                
                const dateTimeIST = new Date(Date.UTC(dateTimeUTC.getUTCFullYear(), dateTimeUTC.getUTCMonth(), dateTimeUTC.getUTCDate(), hours + 5, minutes + 30, seconds));
                
                
                const timeIST = `${dateTimeIST.getUTCHours()}:${dateTimeIST.getUTCMinutes()}:${dateTimeIST.getUTCSeconds()}`;
                return timeIST;
    };

    return (
        <>
            {
                isLoading ?
                    <div className='overlay'>
                        <Spin size="large" />
                    </div> :
                    <section className={`blogs ${isBlogPage ? 'blogSection' : ''}`}>
                        <div className='container'>
                            <div className='row justify-content-center'>
                                <div className='col-md-12'>
                                    <div className='sectionTitle'>
                                        <h2><span>Our</span> Blogs</h2>
                                    </div>
                                    <h2 className='subTitle'>
                                        Latest Blog & News
                                    </h2>
                                </div>
                                {isContent.map((blogItem, index) => {
                                    return (
                                        <div className='col-lg-4 col-md-6 mb-4' key={index}>
                                            <div className="card blogCard">
                                                <div className="blogBanner">
                                                    <img src={blogItem.banner_image} alt="blog banner" />
                                                </div>
                                                <div className="blogContent">
                                                    <h3 className="blogTitle">
                                                    {console.info({blogItem})}
                                                        <a href={`/blog-details/${blogItem.slug}`}>{blogItem.blog_title}</a>
                                                    </h3>
                                                    <div className="wrapper">
                                                        <div className="blogPublishDate">
                                                            <FaRegCalendarAlt />
                                                            {formatDate(blogItem.created_at)}
                                                        </div>
                                                        <div className="blogPublishDate">
                                                             <IoIosTime />
                                                            {formatTimeIST(blogItem.created_at)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
            }
        </>
    )
}

export default Blogs;