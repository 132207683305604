import Http from "../Http";

export function userService(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post('api/userprofile/store', values)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getUserInfo(user_id) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get('api/userprofile/get?user_id=' + user_id)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function deleteDocument(user_id, document_name) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(`api/deleteDocument?user_id=${user_id}&document_name=${document_name}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}