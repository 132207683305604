import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaRegCalendarAlt, FaUser } from "react-icons/fa";
import { Input, Spin, Button, Form } from 'antd';
import * as BlogService from '../../services/blogService';
import { useDispatch } from "react-redux";
import CTA from '../../components/CTA';
import ContactFormBlog from '../../components/ContactFormBlog';
import { IoIosTime } from "react-icons/io";

const { Search } = Input;

const BlogDetails = () => {
    const onSearch = (value, _e, info) => console.log(info?.source, value);
    var dispatch = useDispatch();
    const { slug } = useParams();
    const [blogDetails, setBlogDetails] = useState(null);
    const [isContent, setIsContent] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchBlogContent(slug);
        fetchBlogList();
    }, []);


    const fetchBlogContent = () => {
        dispatch(BlogService.blog(slug))
            .then((res) => {
                const blog = res.data.find(blog => blog.slug === slug);
                setBlogDetails(blog);
                setIsLoading(false);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
                setIsLoading(false);
            });
    }

    const fetchBlogList = () => {
        dispatch(BlogService.blog())
            .then((res) => {
                setIsContent(res.data);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    }

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    };

    const formatTimeIST = (time) => {
        const dateTimeString = time;
                const dateTimeUTC = new Date(dateTimeString);
                const hours = dateTimeUTC.getUTCHours();
                const minutes = dateTimeUTC.getUTCMinutes();
                const seconds = dateTimeUTC.getUTCSeconds();
                
                
                const dateTimeIST = new Date(Date.UTC(dateTimeUTC.getUTCFullYear(), dateTimeUTC.getUTCMonth(), dateTimeUTC.getUTCDate(), hours + 5, minutes + 30, seconds));
                
                
                const timeIST = `${dateTimeIST.getUTCHours()}:${dateTimeIST.getUTCMinutes()}:${dateTimeIST.getUTCSeconds()}`;
                return timeIST;
    };

    return (
        <>
            {isLoading ?
                <div className='overlay'>
                    <Spin size="large" />
                </div> : 
               <>
                     <div className='blogDetailsWrapper'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='blogImage'>
                                    <img src={blogDetails.banner_image} className='img-fluid' alt='blogImgae' />
                                </div>
                                <div className='blogContent'>
                                    <ul className='blogMetaData'>
                                        <li>
                                            <FaUser /> Admin
                                        </li>
                                        <li>
                                            <FaRegCalendarAlt /> {formatDate(blogDetails.created_at)}
                                        </li>
                                        <li>
                                            <IoIosTime /> {formatTimeIST(blogDetails.created_at)}
                                        </li>
                                    </ul>
                                    <div className='blogTitle'>
                                        <h2>{blogDetails.blog_title}</h2>
                                        <strong><p style={{color:"red"}}>{blogDetails.short_description}</p></strong>
                                    </div>
                                    <p className='para' dangerouslySetInnerHTML={{ __html: blogDetails.description.replace(/<strong>/g, '<strong style="color: red;">') }} />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='recentBlogs'>
                                    <div className='card recentBlogCard'>
                                        <div className='card-header'>
                                            <h4 className='card-title'>
                                                Recent Blogs
                                            </h4>
                                        </div>
                                        <div className='card-body'>
                                            <div className='recentBlogsList'>
                                                {isContent.map((blog) => (
                                                    <div className='recentBlogsItem' key={blog.id}>
                                                    <div className='imgWrap'>
                                                        <img src={blog.banner_image} className="img-fluid" alt='blogs' />
                                                    </div>
                                                    <div className='recentBlogMeta'>
                                                        <p><FaRegCalendarAlt /> {formatDate(blog.created_at)}</p>
                                                        <p><IoIosTime /> {formatTimeIST(blog.created_at)}</p>
                                                        <a href={`/blog-details/${blog.id}`} className='recentLink'>{blog.blog_title}</a>
                                                    </div>
                                                </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ContactFormBlog/>
                            </div>
                        </div>
                    </div>
                </div>
                <CTA />
               </>
            }
        </>
    )
}

export default BlogDetails;