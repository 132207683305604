import React from 'react';
import { CategoryIconOne, CategoryIconTwo, CategoryIconThree, CategoryIconFour, CategoryIconFive, CategoryIconSix } from '../../constant/images'

const Category = (props) => {
    return(
        <section className='category'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='sectionTitle'>
                            <h2><span>Our Workin</span>g Process</h2>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <div className='categoryItem'>
                            <div className='icon'>
                                <img src={CategoryIconOne} alt='icon' className='img-fluid' />
                            </div>
                            <div className='text'>
                                <h3>{props.textOne}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <div className='categoryItem'>
                            <div className='icon'>
                                <img src={CategoryIconTwo} alt='icon' className='img-fluid' />
                            </div>
                            <div className='text'>
                                <h3>{props.textTwo}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <div className='categoryItem'>
                            <div className='icon'>
                                <img src={CategoryIconThree} alt='icon' className='img-fluid' />
                            </div>
                            <div className='text'>
                                <h3>{props.textThree}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <div className='categoryItem'>
                            <div className='icon'>
                                <img src={CategoryIconFour} alt='icon' className='img-fluid' />
                            </div>
                            <div className='text'>
                                <h3>{props.textFour}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <div className='categoryItem'>
                            <div className='icon'>
                                <img src={CategoryIconFive} alt='icon' className='img-fluid' />
                            </div>
                            <div className='text'>
                                <h3>{props.textFive}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <div className='categoryItem'>
                            <div className='icon'>
                                <img src={CategoryIconSix} alt='icon' className='img-fluid' />
                            </div>
                            <div className='text'>
                                <h3>{props.textSix}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Category;