import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Category from './category';
import AboutSection from './about';
import Services from './services';
// import Event from './event';
import Features from './features';
import Instructor from './instructor';
import Testimonial from './testimonial';
import Blogs from '../blogs';
import Plans from './plans';
import CTA from './cta';
import { Spin } from 'antd';
import {
    BannerLine,
    BannerImage,
    BannerIconOne,
    BannerIconTwo,
    BannerIconThree,
    BannerIconFour,
} from '../../constant/images';
import * as HomepageService from '../../services/homepageService';
import { useDispatch } from "react-redux";

const Index = () => {
    var dispatch = useDispatch();
    let navigate = useNavigate();
    const serviceRef = useRef(null);
    const location = useLocation();
    const [isContent, setIsContent] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handlenavigate = () => {
        navigate('/contact-us')
    }

    useEffect(() => {
        fetchHomeContent();

        const scrollFunction = () => {
            if (location.hash && serviceRef.current) {
                const element = document.querySelector(location.hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        const timeoutId = setTimeout(scrollFunction, 100);

        return () => clearTimeout(timeoutId);
    }, [location]);

    const fetchHomeContent = () => {
        dispatch(HomepageService.homepage())
            .then((res) => {
                setIsContent(res.data[0]);
                setIsLoading(false);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
                setIsLoading(false);
            });
    }

    return (
        <>
            {isLoading ?
                <div className="overlay">
                    <Spin size="large" />
                </div>
                :
                <>
                    <section className='home'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='homeLeft'>
                                        {isContent ?
                                            <h2 className='topHeading'>
                                                {isContent.top_heading}
                                            </h2>
                                            : <h2 className='topHeading'>
                                                Welcome to Rupe<span>zo</span>
                                            </h2>
                                        }
                                        <h2 className="mainHeading">

                                            <span className="underlineImg">{isContent.banner_title} <img src={BannerLine} alt="Line" /></span>
                                        </h2>
                                        <p className="sectionText" dangerouslySetInnerHTML={{ __html: isContent.banner_description }}></p>
                                        <a className='contactBtn'>
                                            <button className="btn btn-secondary" onClick={handlenavigate}>
                                                <p className="btn-text">{isContent.homepage_cta_button_name}</p>
                                                <span className="square"></span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='homeRight'>
                                        <div className='imgBox'>
                                            <img src={isContent ? isContent.banner : BannerImage} className='bannerImg' alt="banner image" width=" 630" height="500" />
                                            <img src={BannerIconOne} className='iconImg icon-1 smooth-zigzag-anim-1' alt="banner image" />
                                            <img src={BannerIconTwo} className='iconImg icon-2 smooth-zigzag-anim-2' alt="banner image" />
                                            <img src={BannerIconThree} className='iconImg icon-3 smooth-zigzag-anim-3' alt="banner image" />
                                            <img src={BannerIconFour} className='iconImg icon-4 drop-anim' alt="banner image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Category
                        textOne={isContent.owp_1}
                        textTwo={isContent.owp_2}
                        textThree={isContent.owp_3}
                        textFour={isContent.owp_4}
                        textFive={isContent.owp_5}
                        textSix={isContent.owp_6}
                    />
                    <AboutSection
                        AboutImg={isContent.banner}
                        subTitle={isContent.about_us_heading}
                        sectionText={isContent.about_us_description}
                        aboutUl={isContent.about_us_bullet_points}
                        buttonText={isContent.about_us_cta_button_name}
                    />
                    <Services
                        ref={serviceRef}
                    />
                    {/* <Event /> */}
                    <Features />
                    <Instructor />
                    <Plans />
                    <Testimonial
                        subTitle={isContent.testimonial_subtitle}
                        sectionText={isContent.testimonial_description}
                        // reviewText={isContent.}
                        CustomerImg={isContent.testimonial_image}
                        CustomerName={isContent.customer_name}
                        CustomerTitle={isContent.customer_designation}
                    />
                    <Blogs />
                    <CTA />
                </>
            }
        </>

    )
}

export default Index;