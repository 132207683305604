import React, { useState, useEffect } from 'react';
import CTA from '../../components/CTA';
import { FaRegCalendarAlt, FaUser } from "react-icons/fa";
import * as EventService from '../../services/eventsService';
import { useDispatch } from "react-redux";
import { Spin } from 'antd';

const Events = () => {
    var dispatch = useDispatch();
    const [isContent, setIsContent] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchEventContent();
    }, []);

    const fetchEventContent = () => {
        dispatch(EventService.events())
            .then((res) => {
                setIsContent(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
                setIsLoading(false);
            });
    }

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    };

    return (
        <>
            {
                isLoading ?
                    <div className='overlay'>
                        <Spin size='large' />
                    </div>
                    :
                    <div className='eventsWrapper'>
                        <section className='innerWrap'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12 mb-5'>
                                        <div className='sectionTitle'>
                                            <h2><span>Eve</span>nts</h2>
                                        </div>
                                        <h2 className='subTitle'>
                                            Join Our Upcoming Events
                                        </h2>
                                    </div>
                                </div>
                                <div className='row'>
                                    {isContent.map((eventItem, index) => {
                                        return (
                                            <div className='col-md-6 mb-4' key={index}>
                                                <div className="card eventCard">
                                                    <div className='eventMeta'>
                                                        <div className='overlayThumb'>
                                                            <a href="/event-detail" className='thumbLink'>
                                                                <img src={eventItem.images} className='img-fluid' alt='events' />
                                                            </a>
                                                        </div>
                                                        <div className='overlayContent'>
                                                            <h2 className='eventTitle'>
                                                                <a href={`/event-details/${eventItem.id}`}>
                                                                    {eventItem.event_name}
                                                                </a>
                                                            </h2>
                                                            <div className='contentMeta'>
                                                                <ul className='listWrap'>
                                                                    <li>
                                                                        <FaUser /> Admin
                                                                    </li>
                                                                    <li>
                                                                        <FaRegCalendarAlt /> {formatDate(eventItem.event_date)}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </section>
                        <CTA />
                    </div>
            }
        </>
    )
}

export default Events;