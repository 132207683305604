import axios from 'axios';
import { DEMO_URL } from "./Helper";

// axios.defaults.withCredentials = true;
axios.defaults.baseURL = DEMO_URL;

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.warn(error);
        return Promise.reject(error);
    }
);

axios.interceptors.request.use(function (config) {

    const token = `Bearer ${localStorage.getItem('auth_token')}`;
    config.headers.Authorization = token;
    return config;
});

export default axios;