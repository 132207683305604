
const initialState = {
    userDetails: {},
};

const State = (state = initialState, { type, payload = null }) => {
    switch (type) {

        case 'AUTH_USER':
            return setUserDetails(state, payload);
        default:
            return state;
    }
};

function setUserDetails(state, payload) {

    return {
        ...state,
        userDetails: payload.data,
    };
}



export const getAuth = (state) => state.auth.isLoggedIn;

export default State;