import React, { useState } from "react";
import { Button, Form, Input, notification,Spin } from 'antd';
import { useNavigate } from "react-router-dom";
import * as LoginServices from "../../services/loginService";
import { useDispatch } from "react-redux";
import * as Actions from "../../store/actions/user";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const LoginForm = () => {
    var dispatch = useDispatch();
    let navigate = useNavigate();
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [loader, setLoader] = useState(false);

    const [loginInput, setLoginInput] = useState({
        username: "",
        password: "",
    });

    const handleInput = (e) => {
        setLoginInput({ ...loginInput, [e.target.name]: e.target.value });
    };

    const onFinish = (e) => {
        setLoader(true);
        setSubmitting(true);
        const data = {
            username: loginInput.username,
            password: loginInput.password,
        };
        dispatch(LoginServices.login(data))
            .then((res) => {
                setLoader(false);
                console.log(res, "ress")
                if (res.success === true) {
                    setSubmitting(false);
                    localStorage.setItem("isLoggedIn", true);
                    localStorage.setItem("user_id", res.user_id);
                    dispatch(Actions.set_user_auth(res));
                    notification.open({
                        message: "Successfully logged in!",
                        icon: (
                            <CheckCircleOutlined
                                style={{
                                    color: "green",
                                }}
                            />
                        ),
                    });
                    navigate("/");
                } else {
                    setLoader(false);
                    notification.open({
                        message: "Invalid credentials!",
                        icon: (
                            <CloseCircleOutlined
                                style={{
                                    color: "#d62b47",
                                }}
                            />
                        ),
                    });
                    setSubmitting(false);
                    console.error(res);
                }
            })
            .catch((err) => {
                setLoader(false);
                setSubmitting(false);
                notification.error({
                    message: "Invalid credentials",
                });
                console.info("Get Error Edit State...");
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            name="LoginForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className='loginForm'
            form={form}
        >
            <h2>Sign in</h2>
            <Form.Item
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'Please input your username!',
                    },
                ]}
            >
                <Input
                    name="username"
                    className='formControl'
                    placeholder='Username'
                    onChange={handleInput}
                    value={loginInput.username}
                    prefix={<i className="fas fa-user" aria-hidden="true"></i>}
                />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password
                    name="password"
                    className='formControl'
                    onChange={handleInput}
                    value={loginInput.password}
                    placeholder='Password'
                    prefix={<i className="fas fa-lock"
                        aria-hidden="true"></i>}
                />
            </Form.Item>
                
            <Form.Item>
                <div className="forgotLinkRow">
                    <a href="/forgot-passowrd" className="forgotLink">Forgot Password?</a>
                </div>
            </Form.Item>

            <Form.Item>
                <Button disabled={submitting} type="primary" htmlType="submit" className='loginBtn'>
                {loader ? (<><Spin /> <p>Processing</p></>):(<>Login</>)}
                </Button>
            </Form.Item>
            {/* <p className='orTextRow'>
                Or Sign in with social platforms
            </p>
            <div className='socialLinks'>
                <a href="#" className="socialIcon">
                    <i className="fab fa-facebook-f" aria-hidden="true"></i>
                </a>
                <a href="#" className="socialIcon">
                    <i className="fab fa-twitter" aria-hidden="true"></i>
                </a>
                <a href="#" className="socialIcon">
                    <i className="fab fa-google" aria-hidden="true"></i>
                </a>
                <a href="#" className="socialIcon">
                    <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                </a>
            </div> */}
        </Form>
    )
}

export default LoginForm;