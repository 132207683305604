import React, { useRef } from "react";
import Scrollspy from 'react-scrollspy';

const ContentSection = (props) => {
    const sectionRefs = {
        'overview': useRef(null),
        'benifits': useRef(null),
        'procedure': useRef(null),
        'documents': useRef(null),
        'support': useRef(null)
    };


    return (
        <section className="contentSection">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <aside className="sidebar">
                            <div className="sidebarMenu">
                            <Scrollspy items={['overview', 'benifits', 'procedure', 'documents', 'support']} currentClassName="active">
                                <li><a href="#overview">Overview</a></li>
                                <li><a href="#benifits">Benefits</a></li>
                                <li><a href="#procedure">Procedure</a></li>
                                <li><a href="#documents">Documents</a></li>
                                <li><a href="#support">Support</a></li>
                            </Scrollspy>
                            </div>
                        </aside>
                    </div>
                    <div className="col-md-9">
                        <div className="contentWrapper">
                            <div className="card contentCard" id="overview" ref={sectionRefs['overview']}>
                                <div className="card-body">
                                    <h2 className="cardTitle">{props.overviewTitle}</h2> 
                                    <p className="cardContent para" dangerouslySetInnerHTML={{ __html: props.overviewDescription.replace(/<strong>/g, '<strong style="color: red;">') }} />
                                </div>
                            </div>
                            <div className="card contentCard" id="benifits" ref={sectionRefs['benifits']}>
                                <div className="card-body">
                                    <h2 className="cardTitle">{props.benifitsTitle}</h2>
                                    <p className="cardContent mb-3 para" dangerouslySetInnerHTML={{ __html: props.benifitsDescription.replace(/<strong>/g, '<strong style="color: red;">') }} />
                                    
                                </div>
                            </div>
                            <div className="card contentCard" id="procedure" ref={sectionRefs['procedure']}>
                                <div className="card-body">
                                    <h2 className="cardTitle">{props.procedureTitle}</h2> 
                                    <p className="cardContent mb-3 para" dangerouslySetInnerHTML={{ __html: props.procedureDescription.replace(/<strong>/g, '<strong style="color: red;">') }} />
                                </div>
                            </div>
                            <div className="card contentCard" id="documents" ref={sectionRefs['documents']}>
                                <div className="card-body">
                                    <h2 className="cardTitle">{props.documentsTitle}</h2> 
                                    <p className="cardContent para" dangerouslySetInnerHTML={{ __html: props.documentsDescription.replace(/<strong>/g, '<strong style="color: red;">') }} />
                                </div>
                            </div>
                            <div className="card contentCard" id="support" ref={sectionRefs['support']}>
                                <div className="card-body">
                                    <h2 className="cardTitle">{props.supportTitle}</h2>  
                                    <p className="cardContent para" dangerouslySetInnerHTML={{ __html: props.supportDescription.replace(/<strong>/g, '<strong style="color: red;">') }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContentSection;