import React, { useState, useEffect } from "react";
import { Logo } from '../../constant/images';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Dropdown, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import * as ServicePage from '../../services/servicesPage';
import { useDispatch } from "react-redux";

function Navbar() {
    const navigate = useNavigate();
    var dispatch = useDispatch();
    const [isContent, setIsContent] = useState([]);
    const userDetails = useSelector((state) => state?.User?.userDetails);

    const navigateToContacts = () => {
        navigate('/signup');
    };

    const logout = () => {
        localStorage.removeItem('isLoggedIn');
        navigate('/');
    };

    const isLoggedIn = localStorage.getItem("isLoggedIn");

    const items = [
        {
            label: <a href="/profile">My Profile</a>,
            key: '0',
        },
        {
            label: <button type="button" className="logoutBtn" onClick={logout}>Log out</button>,
            key: '1',
        },
    ]

    useEffect(() => {
        fetchServiceContent();
    }, []);

    const fetchServiceContent = () => {
        dispatch(ServicePage.servicePage())
            .then((res) => {
                setIsContent(res.data);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    }

    const groupedContent = {};
    isContent.forEach((menu) => {
        if (!groupedContent[menu.setting]) {
            groupedContent[menu.setting] = [];
        }
        groupedContent[menu.setting].push(menu.top_heading);
    });

    return (
        <>
            <header className="header container-fluid">
                <div className="topHeader">
                    <ul className="topList">
                        <li>
                            <a href="tel:+918559055905" className="phone_email font-weight-bold">
                                <i className="ri-phone-fill"></i> +918559055905
                            </a>
                        </li>
                        <li>
                            <a style={{fontSize:"1.1rem",textDecoration:"underline"}} href="mailto: info@rupezo.com" className="phone_email font-weight-bold">
                                <i className="ri-mail-line"></i>info@rupezo.com
                            </a>
                        </li>
                    </ul>
                </div>
                <nav className="navbar navbar-expand-lg py-0">
                    <a className="navbar-brand" href="/">
                        <img className="rupezo-logo" src={Logo} alt="Rupezo Logo" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/about-us">About</a>
                            </li>
                            {/* <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                                </li> */}
                            <li className="dropdown__item customDropdownItem">
                                <div className="nav__link dropdown__button">
                                    Services <i className="ri-arrow-down-s-line dropdown__arrow"></i>
                                </div>
                                <div className="dropdown__container">
                                    <div className="dropdown__content">
                                        {Object.keys(groupedContent).map((setting, index) => (
                                            <div className="dropdown__group" key={index}>
                                            <div className="dropdownTitle">
                                                <div className="dropdown__icon">
                                                    {setting === "Firm Registration" ? <i className="ri-flashlight-line"></i> :
                                                        setting === "Registration" ? <i className="ri-heart-3-line"></i> :
                                                            setting === "License" ? <i className="ri-book-mark-line"></i> :
                                                                <i className="ri-file-paper-2-line"></i>
                                                    }
                                                </div>
                                                <span className="dropdown__title">{setting}</span>
                                                </div>
                                                <ul className="dropdown__list">
                                                    {groupedContent[setting].map((heading, index) => (
                                                        <li key={index}>
                                                            <a href={`/services/${heading}`} className="dropdown__link">{heading}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/blogs">Blogs</a>
                            </li>
                        </ul>
                        <form className="d-flex">
                            {isLoggedIn ?
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    trigger={['click']}
                                    className="profileBtn"
                                >
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Avatar icon={<UserOutlined />} size="medium" className="me-2" />User
                                    </a>
                                </Dropdown> :
                                <button className="btn2 btn2-primary2" onClick={navigateToContacts}>
                                    <p className="btn2-text">Login / SignUp</p>
                                    <span className="square1"></span>
                                </button>
                            }
                        </form>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Navbar;